let deduction = [
    ["28,000", "19,000", "19,000", "15,000", "11,000", "7,000", ""],
    ["31,000", "23,000", "23,000", "18,000", "14,000", "10,000", "3,000"],
    ["34,000", "26,000", "26,000", "22,000", "18,000", "13,000", "5,000"],
    ["38,000", "29,000", "29,000", "25,000", "21,000", "17,000", "8,000"],
    ["42,000", "33,000", "33,000", "29,000", "25,000", "21,000", "12,000"],
    ["45,000", "37,000", "37,000", "33,000", "29,000", "24,000", "16,000"],
    ["52,000", "41,000", "41,000", "37,000", "33,000", "28,000", "20,000"],
    ["56,000", "45,000", "45,000", "40,000", "36,000", "32,000", "24,000"],
    ["61,000", "49,000", "49,000", "44,000", "40,000", "36,000", "28,000"],
    ["65,000", "56,000", "56,000", "49,000", "44,000", "40,000", "31,000"],
    ["69,000", "60,000", "60,000", "57,000", "48,000", "44,000", "35,000"],
    ["73,000", "64,000", "64,000", "61,000", "56,000", "48,000", "39,000"],
    ["77,000", "69,000", "69,000", "66,000", "60,000", "57,000", "43,000"],
    ["81,000", "73,000", "73,000", "70,000", "64,000", "61,000", "48,000"],
    ["97,000", "77,000", "77,000", "74,000", "68,000", "65,000", "53,000"],
    ["102,000", "81,000", "81,000", "78,000", "73,000", "70,000", "62,000"],
    ["108,000", "86,000", "86,000", "83,000", "78,000", "75,000", "66,000"],
    ["113,000", "104,000", "104,000", "88,000", "82,000", "79,000", "71,000"],
    ["118,000", "109,000", "109,000", "106,000", "87,000", "84,000", "76,000"],
    ["124,000", "114,000", "114,000", "111,000", "105,000", "89,000", "80,000"],
    ["129,000", "120,000", "120,000", "116,000", "110,000", "107,000", "85,000"],
    ["135,000", "125,000", "125,000", "122,000", "116,000", "112,000", "90,000"],
    ["140,000", "131,000", "131,000", "127,000", "121,000", "118,000", "108,000"],
    ["146,000", "137,000", "136,000", "132,000", "126,000", "123,000", "114,000"],
    ["152,000", "143,000", "141,000", "138,000", "132,000", "128,000", "119,000"],
    ["159,000", "150,000", "148,000", "144,000", "138,000", "135,000", "125,000"],
    ["166,000", "157,000", "154,000", "150,000", "144,000", "141,000", "131,000"],
    ["173,000", "164,000", "160,000", "157,000", "151,000", "147,000", "138,000"],
    ["180,000", "171,000", "166,000", "163,000", "157,000", "153,000", "144,000"],
    ["218,000", "202,000", "194,000", "191,000", "185,000", "181,000", "172,000"],
    ["247,000", "247,000", "232,000", "229,000", "229,000", "219,000", "206,000"],
    ["326,000", "326,000", "261,000", "258,000", "261,000", "248,000", "248,000"],
    ["360,000", "360,000", "343,000", "339,000", "343,000", "277,000", "277,000"],
    ["395,000", "395,000", "377,000", "373,000", "377,000", "361,000", "361,000"],
    ["429,000", "429,000", "412,000", "408,000", "412,000", "396,000", "396,000"],
    ["463,000", "463,000", "446,000", "442,000", "446,000", "430,000", "430,000"],
    ["498,000", "498,000", "481,000", "477,000", "481,000", "465,000", "465,000"],
    ["533,000", "533,000", "516,000", "512,000", "516,000", "500,000", "500,000"],
    ["569,000", "569,000", "552,000", "548,000", "552,000", "536,000", "536,000"],
    ["604,000", "604,000", "587,000", "583,000", "587,000", "571,000", "571,000"],
    ["640,000", "640,000", "623,000", "619,000", "623,000", "607,000", "607,000"],
    ["773,000", "773,000", "754,000", "749,000", "754,000", "642,000", "642,000"],
    ["814,000", "814,000", "795,000", "790,000", "795,000", "776,000", "776,000"],
    ["855,000", "855,000", "835,000", "830,000", "835,000", "817,000", "817,000"]
];
jQuery(function(){
  $('#deduction1,#deduction2').change(function() {
    try {
      var key1 = $('#deduction1').val();
      var key2 = $('#deduction2').val();
      if (!!deduction[key1][key2]) {
        $('#deduction-result').html("約" + deduction[key1][key2] + "円");
      } else {
        $('#deduction-result').html("");
      }
    }catch(e){}
  });
});